<template>
  <div class="space-y-4">
    <span class="text-headline">
      {{ $t("COMMON.CHOOSE") }} {{ $t("COMMON.CONTACT") }}
    </span>
    <div class="border rounded p-4 space-y-2">
      <lf-checkbox
        v-if="currentUser?.email"
        :value="currentUser.id"
        name="current_user"
        v-model="selectedUsersIds"
      >
        <div class="flex flex-col space-y-1">
          <span class="text-headline">
            {{ $t("ORGANIZATION.EMAIL_TEMPLATES.MY_EMAIL") }}
          </span>
          <span class="text-label">{{ currentUser.email }}</span>
        </div>
      </lf-checkbox>
      <div v-if="contacts?.length" class="flex flex-col space-y-2">
        <lf-checkbox
          value="organization_contacts"
          name="organization_contacts"
          v-model="organizationContacts"
        >
          <div class="flex flex-col space-y-1">
            <span class="text-headline">
              {{ $t("ORGANIZATION.EMAIL_TEMPLATES.ORGANIZATION_CONTACTS") }}
            </span>
            <span class="text-label">
              {{ $t("ORGANIZATION.EMAIL_TEMPLATES.FROM_YOUR_ORGANIZATION") }}
            </span>
          </div>
        </lf-checkbox>
        <div v-if="organizationContacts" class="space-y-1">
          <search-input
            v-model.trim="searchText"
            no-margin
            no-padding
            data-cy="search-input"
          />
          <div class="max-h-60 space-y-1 overflow-y-auto">
            <label
              v-for="user in filteredUsers"
              :key="user.id"
              :data-cy="`user-${user.id}`"
              class="flex items-center justify-between hover:bg-primary-background-active p-2 cursor-pointer rounded"
              :class="{
                'bg-primary-background-active': selectedUsersIds?.includes(
                  user.id
                )
              }"
            >
              <input
                class="hidden"
                type="checkbox"
                v-model="selectedUsersIds"
                :value="user.id"
              />
              <div class="flex flex-col space-y-1">
                <span class="text-headline">{{ getUserFullName(user) }}</span>
                <span class="text-label">{{ user.email }}</span>
              </div>
              <icon-base
                v-if="selectedUsersIds?.includes(user.id)"
                :icon="IconCheck"
                :icon-color="UTIL_COLORS.PRIMARY"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import IconCheck from "@/components/icons/IconCheck.vue";
import LfCheckbox from "@/components/ui/inputs/LfCheckbox.vue";
import SearchInput from "@/components/ui/inputs/SearchInput.vue";

import type { TestEmailData } from "@/models/emails";
import type { IUser } from "@/models/users";
import { computed, ref, type PropType } from "vue";
import { useStore } from "vuex";
import { UTIL_COLORS } from "@/helpers/constants";

const props = defineProps({
  contacts: {
    type: Array as PropType<TestEmailData["contacts"]>
  }
});

const selectedUsersIds = defineModel<number[]>({ default: [] });

const { getters } = useStore();

const organizationContacts = ref(false);
const searchText = ref("");

const filteredUsers = computed<TestEmailData["contacts"]>(() => {
  if (!searchText.value) {
    return props.contacts || [];
  }

  return (
    props.contacts?.filter(({ last_name, first_name, email }) =>
      `${last_name}${first_name}${email}`
        .toLowerCase()
        .includes(searchText.value.toLowerCase())
    ) || []
  );
});

const currentUser = computed<IUser>(() => getters["auth/user"]);

const getUserFullName = ({
  first_name,
  last_name
}: {
  first_name: string;
  last_name: string;
}) => [first_name, last_name].filter(Boolean).join(" ");
</script>
